const Path = {
    HOME: "/",
    ABOUT:"/about",
    CONTACT:"/contact",
    COURSES:"/courses",
    CAREER:"/career",
    PLACEMENT:"/placement",
    WORKSHOP:"/workshop",
    JOBS: "/jobs",
    TERMS_CONDITIONS: '/terms-conditions',
    LIFE_AT_MINDCODERS: '/life-at-mindcoders',
    PRIVACY_POLICY: '/privacy-policy',
    COURSE_DSA:"/courses/dsa",
    COURSE_PYTHON:"/courses/python",
    COURSE_PYTHON_FULLSTACK:"/courses/python-fullstack",
    COURSE_JAVA:"/courses/java",
    COURSE_JAVA_FULLSTACK:"/courses/java-fullstack",
    COURSE_FRONTEND:"/courses/frontend",
    COURSE_BACKEND:"/courses/backend",
    COURSE_MERN:"/courses/mern",
    COURSE_REACT:"/courses/react",
    COURSE_DIGITALMARKETING:"/courses/digital-marketing",
    COURSE_DATA_ANALITICS:"/courses/data-analitics",
    COURSE_DATA_SCIENCE:"/courses/data-science",
    INTERNSHIP: "/internship",
    BLOG:"/blog",
    BLOG_DETAILED: "/blog/:id"
  };
  export default Path;
  