import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { testimonialData } from "../data/testimonialData";
import { baseUrl } from "../helpers/baseUrl";

const Testimonial = () => {

    console.log(testimonialData);
    

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // screen width <= 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600, // screen width <= 600px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <section className="testimonial-section bg-light-subtle pt-12 pb-12">
      <div data-aos="fade-up" data-aos-duration="1000" className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h4 className="text-info text-2xl font-bold md:text-3xl">
            Testimonial
          </h4>
          <h2 className="text-3xl md:text-4xl font-semibold text-color-navy">
            What’s Student's Say
          </h2>
          <p className="mt-4 max-w-2xl mx-auto">
            We are incredibly grateful for the positive testimonials and reviews
            we've received from our valued students. Your kind words inspire and
            motivate us to continually strive for excellence.
          </p>
        </div>
        <Slider
          {...settings}
          className="flex flex-wrap justify-center text-justify"
        >
          {testimonialData.map((testimonial) => (
            <div key={testimonial.id} className="px-2">
              <div className="p-4 bg-white rounded-lg shadow-md max-w-md mx-auto">
                <p className="mb-4">{testimonial.content}</p>
                <div className="flex items-center">
                  <img
                    src={`${baseUrl()}/${testimonial?.image}`}
                    alt="author"
                    loading="lazy"
                    className="rounded-full w-16 h-16 mr-4"
                  />
                  <div>
                    <h6 className="font-semibold">{testimonial.author}</h6>
                    <span className="text-sm">{testimonial.role}</span>
                    <ul className="flex">
                      {Array(testimonial.rating)
                        .fill()
                        .map((_, i) => (
                          <li key={i} className="text-info">
                            <i className="fas fa-star" />
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonial;
