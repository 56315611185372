import React from "react";
import IconGlobe from "../components/IconGlobe";
import { baseUrl } from "../helpers/baseUrl";

const AboutUsPage = () => {
  return (
    <>
      <section className="feature-section-two ptb-40">
        <div className="container overflow-x-hidden">
          <div className="flex flex-col-reverse md:flex-row align-items-center justify-content-lg-between justify-content-center gap-4">
            <div className="col-lg-7 col-md-7 col-xl-7 col-sm-12">
              <div className="feature-content-wrap">
                <div className="section-heading">
                  <h2
                    className="pb-4 h2"
                    data-aos="fade-right" data-aos-duration="1000"
                  >
                    We have development{" "}
                    <span className="text-info">experts</span> in all{" "}
                    <span className="text-info">technologies</span>
                  </h2>
                  <p data-aos="fade-right" data-aos-duration="1300">
                    Welcome to Mind-Coders, your premier destination for
                    cutting-edge IT training solutions. With a commitment to
                    excellence, we offer a diverse range of courses catering to
                    various skill levels and industry demands. Our expert
                    instructors bring a wealth of experience, ensuring a dynamic
                    learning experience for our students. Whether you're a
                    beginner looking to enter the IT field or a professional
                    seeking to upgrade skills, our courses cover a spectrum of
                    topics including programming languages, cybersecurity, cloud
                    computing, and more. Explore our state-of-the-art facilities
                    and engaging online platform designed to foster optimal
                    learning outcomes. At Mind-Coders, we pride ourselves on
                    staying ahead in the rapidly evolving tech landscape,
                    providing you with the knowledge and skills needed to
                    succeed. Join us on a transformative learning journey and
                    empower your career in the world of IT.
                  </p>
                </div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-5 col-md-5 col-xl-5 col-sm-12">
              <IconGlobe />
            </div>
          </div>
        </div>
      </section>
      {/* About section end */}
      {/* work process section start */}
      <section className="work-process overflow-x-hidden">
        <div data-aos="fade-up" data-aos-duration="1000" className="container">
          <div className="row justify-content-center">
            <div className="section-heading text-center">
              <h4 className="text-info h4">Process</h4>
              <h2 className="h2">We Follow Our Work Process</h2>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12">
              <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0">
                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                  <span className="h2 mb-0 text-info fw-bold">1</span>
                </div>
                <h3 className="h5">Research</h3>
                <p className="mb-0">
                  Uniquely pursue restore efficient for initiatives expanded.
                </p>
              </div>
            </div>
            <div className="dots-line first" />
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12">
              <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0">
                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                  <span className="h2 mb-0 text-info fw-bold">2</span>
                </div>
                <h3 className="h5">Planning</h3>
                <p className="mb-0">
                  Restore efficient human pursue for compelling initiatives.
                </p>
              </div>
            </div>
            <div className="dots-line first" />
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12">
              <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0 mb-md-0">
                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                  <span className="h2 mb-0 text-info fw-bold">3</span>
                </div>
                <h3 className="h5">Execute</h3>
                <p className="mb-0">
                  Continually enhance pursue compelling initiatives enhance.
                </p>
              </div>
            </div>
            <div className="dots-line first" />
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-12">
              <div className="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-0">
                <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                  <span className="h2 mb-0 text-info fw-bold">4</span>
                </div>
                <h3 className="h5">Deliver</h3>
                <p className="mb-0">
                  Uniquely for compelling initiatives expanded interactive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* work process section end */}
      {/*Choose us start*/}
      <section className="pt-5">
        <div className="container overflow-x-hidden border-2 text-gray-300 bg-blue-950 rounded-xl p-4">
          <div className="flex flex-col lg:flex-row justify-start items-start lg:items-center">
            <h3 className="text-white pe-3 me-3 border-r-0 lg:border-r-2 h3 mb-4 lg:mb-0">
              Why Mindcoders?
            </h3>
            <p className="text-sm lg:text-base">
              Choose our company for unparalleled value, industry expertise, and
              transparent practices. Our commitment to quality is backed by
              certifications, and customer testimonials showcase our positive
              impact. With a focus on innovation and a customer-centric
              approach, we invite you to explore the unique benefits we offer.
              Your journey with us is guided by a clear call-to-action, ensuring
              a seamless and satisfying experience.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-5 gap-5 items-center">
            <div data-aos="fade-right" data-aos-duration="1200" className="w-full h-[200px] border-x-0 lg:border-x-2 p-3">
              <div className="w-[50px]">
                <img
                  src={`${baseUrl()}/assets/img/animated-icons/mentorship.gif`}
                  alt="icon"
                  loading="lazy"
                />
              </div>
              <h4 className="text-white h6 mt-2">Superb mentors</h4>
              <p className="text-sm lg:text-base">
                Best in class mentors from top Tech schools and Industry
                favourite Techies are here to teach you.
              </p>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="w-full h-[200px] p-3">
              <div className="w-[50px]">
                <img
                  src={`${baseUrl()}/assets/img/animated-icons/web-structure.gif`}
                  alt="icon"
                  loading="lazy"
                />
              </div>
              <h4 className="text-white h6 mt-2">Industry-vetted curriculum</h4>
              <p className="text-sm lg:text-base">
                Best in class content, aligned to the Tech industry is delivered
                to you to ensure you are a darling of the Tech industry.
              </p>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="w-full h-[200px] border-s-0 lg:border-s-2 p-3">
              <div className="w-[50px]">
                <img
                  src={`${baseUrl()}/assets/img/animated-icons/coding.gif`}
                  alt="icon"
                  loading="lazy"
                />
              </div>
              <h4 className="text-white h6 mt-2">Project-based learning</h4>
              <p className="text-sm lg:text-base">
                Hands-on learning pedagogy with live projects to cover practical
                knowledge over theoretical one.
              </p>
            </div>
            <div data-aos="fade-left" data-aos-duration="1200" className="w-full h-[200px] border-x-0 lg:border-x-2 p-3">
              <div className="w-[50px]">
                <img
                  src={`${baseUrl()}/assets/img/animated-icons/suitcase.gif`}
                  alt="icon"
                  loading="lazy"
                />
              </div>
              <h4 className="text-white h6 mt-2">Superb placements</h4>
              <p className="text-sm lg:text-base">
                Result-oriented courses with placement across all genres,
                students as well as working professionals.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* workshop section start */}
      <section className="container overflow-x-hidden mt-20">
        <div data-aos="fade-up" data-aos-duration="1000">
          <h1 className="h1">
            Workshops<span className="text-info"> &amp; </span> Seminars{" "}
          </h1>
          <div className="row flex-wrap">
            <div className="col-lg-7 col-xl-6 col-md-4 col-sm-12 gap-2 ">
              <div className="row align-content-center">
                <div className="col-lg-8 col-xl-8 col-md-8 col-sm-12 ">
                  <img
                    className="workshop-img1"
                    src="./assets/img/workshops-img/23.webp"
                    width="100%"
                    alt="img"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                  <img
                    className="workshop-img2"
                    src="./assets/img/workshops-img/4.webp"
                    width="100%"
                    alt="img"
                    loading="lazy"
                  />
                </div>
                <div className="animations one">
                  <img
                    className="dance1"
                    src="./assets/img/circle-img2.webp"
                    alt="img"
                    loading="lazy"
                  />
                </div>
                <div className="animations two">
                  <img
                    className="dance2"
                    src="./assets/img/circle-img.webp"
                    alt="img"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-6 col-md-8 col-sm-12">
              <div className="wall">
                <h3>Benefits of workshops</h3>
                <div className="back">
                  <ul>
                    <li className="xs-max">
                      <span>Meet with industry experts</span>
                    </li>
                    <li className="xs">
                      <span id="xs">Career opportunities</span>
                    </li>
                    <li className="xs-max">
                      <span>Trending Technologies</span>
                    </li>
                    <li className="x">
                      <span id="xs">Corporate Training</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* workshop section end */}
    </>
  );
};

export default AboutUsPage;
