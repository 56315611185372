import React from "react";
import Tabs from "../../components/Tabs";
import { digitalMarketingData } from "../../data/courseTabData";
import { digitalMarketing } from "../../data/courseCurriculamData";

const DigitalMarketingPage = () => {
  return (
    <>
        {/*hero section start*/}
        <section className="ptb-120 d-flex align-items-center position-relative">
          <div className="container ">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-8">
                <div className="hero-content-wrap d-flex gap-2">
                  <div className="divider">
                    <span className="saperater" />
                  </div>
                  <div>
                    <h1 className="h1 course-heading">Digital Marketing Course</h1>
                    <p className="course-overview">
                      Basic overview of what you will learn during our 90 Days of
                      Digital Marketing Training in Indore. Enroll Now!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="hero-content-wrap">
                  <img
                    className="course-image"
                    src={`${window.location.origin.toString()}/assets/img/courses-logo/digitalMarketing-logo.webp`}
                    alt="img"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {digitalMarketing.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>{topic}</li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
          </div>
        </section>
        {/*hero section end*/}
        <Tabs content={digitalMarketingData} />

    </>
  );
};

export default DigitalMarketingPage;
