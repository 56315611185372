const courseFeatures = [
    "Certifiation",
    "Expert Trainer",
    "Internship Certification",
    "Weekly Doubt Sessions",
    "1:1 mentorship"
];

const faq = [
    {
        category: "Certification",
        questions: [
            {
                que: "How can i get a certification after completing a course?",
                ans: "As soon as Course is completed, We will issue your Certificate next day itself(with hard copy)"
            },
            {
                que: " Can i update my cerification detail if there is any error?",
                ans: "Yes."
            }
        ]
    },
    {
        category: "Payment problems and methods",
        questions: [
            {
                que: "what should i do if I encounter a problem with my online payment?",
                ans: "You can raise the issue on our whatsapp or call us on +91 7674040233."
            },
            {
                que: "Are there any fees associated with payment processing?",
                ans: "No, we Dont charge any extra processing fees."
            },
            {
                que: "What payment methods do you accept for online purchases?",
                ans: "We accept credit and devit cards , upi and ard swipe machine."
            },
        ]
    },
    {
        category: "How to refund a course",
        questions: [
            {
                que: "What is the method to request a refund for a course?",
                ans: "You can call us on +91-7674040233 or visit the institute and raise your concern with reasons."
            },
            {
                que: "What is the method o check my refund status?",
                ans: "You can call us on +91-7674040233."
            },
            {
                que: "Are there any proceesing fees for refunding a course?",
                ans: "Only 25% of the registration i.e., 250rs. , Rest is all refunded."
            },
        ]
    },
    {
        category: "Fees",
        questions: [
            {
                que: "Can i pay for the course in EMI?",
                ans: "Yes ,you can pay in monthly instalments."
            },
            {
                que: "Will there be extra charges demanded after paying fee?",
                ans: "Yes, we offer discounts upto 10% but only if the course's  fee is paid in one time and not in installmets."
            }
        ]
    },
    {
        category: "Internships",
        questions: [
            {
                que: "What is the duration and stipend of your internships?",
                ans: "Our internship program ranges from 2-3 months and stipend is based on interview process."
            },
            {
                que: "Are internships available remotely or on-site?",
                ans: "We currently offer only on-site Internships."
            },
            {
                que: "What type of internships do you  offer?",
                ans: "We are currently offering Business development executive (BDE) and Human Resource (HR)."
            }
        ]
    },
]

// Subject wise content ----------------
export const mernData =
{
    course: "MERN",
    description: "MERN Stack is a JavaScript Stack that is used for easier and faster deployment of full-stack web applications. MERN Stack comprises of 4 technologies namely: MongoDB, Express, React and Node.js. It is designed to make the development process smoother and easier.",
    content: [
        {
            value:
                "Learn about the foundational languages of web development HTML,CSS and JS . Learn the basics of HTML, including elements, attributes, and document structure.Understand how to style web pages using CSS, including layouts, responsive design, and animations .Master JavaScript fundamentals such as variables, data types, functions, loops, and DOM manipulation. ",
            label: "Foundational Web Development",
        },
        {
            value:
                "Learn about Front-End development this includes React Basics : Get introductions to React , including components , JSX and state management . React Router : Learn to handle routing in React applications for multi-page setups . State Mangement : Explore advanced state management techiniques with context API and Redux . Hooks : Understand and Utilize React Hooks for functional component lifecycle and State Management ",
            label: "Front-End Development with React",
        },
        {
            value:
                "Learn about Back-End Development with Node js framework Express js , Node.js Fundamentals: Learn the basics of Node.js, including modules, event-driven programming, and file system operations . Express.js Basics: Get introduced to Express.js and learn how to build a simple web server.RESTful APIs: Understand how to design and implement RESTful APIs using Express.js . Middleware: Learn about middleware functions and their role in Express applications.",
            label: "Back-End Development with Node.js and Express.js",
        },
        {
            value:
                "Learn about Database (MongoDB) . MongoDB Basics: Learn the fundamentals of MongoDB, including collections, documents, and CRUD operations . Mongoose: Understand how to use Mongoose for schema-based data modeling in MongoDB . Database Integration: Integrate MongoDB with Node.js and Express.js applications .",
            label: "Database Management with MongoDB",
        },
        {
            value:
                "Connecting Front-End and Back-End: Learn how to connect React front-end with Node.js and Express.js back-end . Authentication and Authorization: Implement user authentication and authorization using JWT (JSON Web Tokens) and Passport.js . Deployment: Understand how to deploy MERN stack applications to platforms like Heroku, Vercel, or DigitalOcean . Project Development: Build and deploy a full-fledged MERN stack application as a capstone project.",
            label: "Full-Stack Application Development",
        },
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
}

export const pythonData =
{
    course: "PYTHON",
    description: "What type of language is Python? Python is an interpreted, object-oriented, high-level programming language with dynamic semantics developed by Guido van Rossum. It was originally released in 1991. Designed to be easy as well as fun, the name Python is a nod to the British comedy group Monty Python. Python has a reputation as a beginner-friendly language, replacing Java as the most widely used introductory language because it handles much of the complexity for the user, allowing beginners to focus on fully grasping programming concepts rather than minute details. Python is used for server-side web development, software development, mathematics, and system scripting, and is popular for Rapid Application Development and as a scripting or glue language to tie existing components because of its high-level, built-in data structures, dynamic typing, and dynamic binding. Program maintenance costs are reduced with Python due to the easily learned syntax and emphasis on readability. Additionally, Python's support of modules and packages facilitates modular programs and reuse of code. Python is an open source community language, so numerous independent programmers are continually building libraries and functionality for it.",
    content: [
        {
            "value": "This includes understaning the fundamental , history and setup & Installation require to run a pythone code on your local machine",
            "label": "Introduction to Python"
        },
        {
            "value": "Learning python operator and data type used in python programming language",
            "label": "Python Operators and Data Types "
        },
        {
            "value": "Understanding contoll statement in python, and conditional like if , else loop like for loop while loops etc",
            "label": "Control Statements and Loops "
        },
        {
            "value": "Introduction to functions in python ,function argument and statements , hands on lab: defining function and exploring Modules",
            "label": "Funtion ,Module and Packages "
        },
        {
            "value": "overview of object oriented programming concepts , Encapsulation, Inheritance and Polymorphosim",
            "label": "Object Oriented Programming (OOP)"
        },
        {
            "value": "Understanding Exceptional and Error Handling , Try Expect and Else Block , Try-finally Clause and Raising Exception ... and many more topics related to Exception handling",
            "label": "Exceptional handling and File I/O"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
}

export const digitalMarketingData =
{
    course: "DITITAL_MARKETING",
    description: "The digital marketing course offered by Mind Coders is designed to transform individuals into expert digital marketers by providing a comprehensive understanding of various digital marketing strategies and tools. The course spans over 100 hours of content and includes a 12-week internship program, ensuring both theoretical knowledge and practical experience.",
    content: [
        {
            "value": "The course begins with an introduction to digital marketing, emphasizing its importance in the modern business landscape. Students learn the differences between traditional and digital marketing, explore various digital marketing strategies, and delve into techniques for increasing visibility and generating leads.",
            "label": "Digital Marketing Fundamentals"
        },
        {
            "value": " This section covers the creation and management of social media strategies, content creation, community management, and paid advertising on platforms like Facebook and Instagram. Students also learn about the latest trends in social media marketing and how to analyze and report on campaign performance.",
            "label": "Social Media Marketing (SMM)"
        },
        {
            "value": "Students gain expertise in keyword research, on-page and off-page SEO, technical SEO, and content marketing. The course also covers local SEO and introduces students to essential tools like Google Analytics and SEMrush for monitoring website performance.",
            "label": "Search Engine Optimization (SEO)"
        },
        {
            "value": "This module teaches the fundamentals of SEM, including Google AdWords, PPC marketing, campaign setup, ad copywriting, bid management, and analytics. The focus is on creating effective search engine marketing campaigns that drive traffic and conversions.",
            "label": "Search Engine Marketing (SEM)"
        },
        {
            "value": "This course delves into essential digital marketing strategies, including Link Building, where students learn to implement white hat SEO techniques to acquire quality backlinks and enhance domain authority. It also covers Local Business & Google Mapping, focusing on setting up and optimizing Google My Business listings for improved local search visibility and reputation management. In the Email Marketing module, students gain skills in crafting, automating, and analyzing email campaigns to maximize engagement and conversions. Additionally, the course offers insights into Affiliate Marketing, teaching students how to select profitable niches, optimize conversions, and engage effectively with affiliate networks to build successful marketing campaigns.",
            "label": "Additional Modules"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
}

export const frontendData =
{
    course: "FRONTEND",
    description: "The frontend development course syllabus focuses on building a solid foundation in web technologies, starting with HTML and CSS for creating well-structured, responsive, and aesthetically pleasing web pages. Students then progress to Bootstrap for rapid UI development, followed by a deep dive into JavaScript for adding interactivity and dynamic content to web pages. The curriculum culminates with React.js, where students learn to build modern, single-page applications using component-based architecture, state management techniques, and routing. Emphasis is placed on advanced patterns, performance optimization, and building scalable, maintainable frontend applications.",
    content: [
        {
            "value": "The frontend syllabus begins with HTML and CSS, providing the foundational skills necessary for structuring and styling web pages. Students learn to use semantic HTML for creating accessible and well-structured content. They also delve into advanced CSS techniques, including Flexbox, Grid, and animations, to create responsive and visually engaging web designs. Mastery of these tools ensures that students can build websites that are both functional and aesthetically pleasing across various devices.",
            "label": "HTML & CSS"
        },
        {
            "value": "JavaScript is introduced as the core programming language for adding interactivity and dynamic content to web pages. The syllabus covers essential concepts such as variables, functions, loops, and event handling. Students learn to manipulate the Document Object Model (DOM) to create dynamic user interfaces and use AJAX for fetching data asynchronously, which enhances the performance and user experience of web applications.",
            "label": "JavaScript"
        },
        {
            "value": "Students are taught to use Bootstrap, a popular CSS framework, to expedite UI development with its grid system, components, and utility classes for responsive layouts, customization, and JavaScript plugins. Optionally, they may also explore Tailwind CSS, focusing on utility-first design principles to build custom designs and responsive UI components, allowing them to choose between Bootstrap and Tailwind based on project needs and preferences.",
            "label": "Bootstrao / Tailwind CSS"
        },
        {
            "value": "The React.js module covers the development of modern, single-page applications using a component-based architecture. Students learn to build modular and reusable components, manage application state effectively using hooks and Redux, and implement routing with react-router-dom. The syllabus also explores advanced React concepts such as the context API and custom hooks, as well as performance optimization techniques like code splitting and lazy loading.",
            "label": "React.js"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
}

export const backendData =
{
    course: "BACKEND",
    description: "The backend development syllabus focuses on building robust and scalable server-side applications using Node.js, Express.js, and MongoDB. Students start with mastering asynchronous programming in Node.js, learning to create efficient web servers and handle various data operations. The curriculum then moves to Express.js, where students build RESTful APIs, manage user authentication with JWT, and implement error handling for reliable server logic. MongoDB is introduced as the primary database, where students learn to design flexible schemas, perform CRUD operations, and optimize queries for better performance. The course also covers advanced backend topics, including real-time communication, secure user authentication, and deploying applications with best practices for stability and scalability.",
    content: [
        {
            "value": "The backend syllabus starts with Node.js, focusing on its non-blocking, event-driven architecture, which is essential for building scalable server-side applications. Students learn asynchronous programming using callbacks, promises, and async/await, as well as managing file systems and handling various data operations efficiently. This module lays the groundwork for developing robust and performant backend services.",
            "label": "Node.js"
        },
        {
            "value": "Express.js is introduced as a powerful web framework for Node.js. The syllabus covers the creation of RESTful APIs, handling HTTP requests and responses, and implementing middleware for routing, authentication, and error handling. Students also learn to handle form data, file uploads, and secure their applications using JSON Web Tokens (JWT) for authentication, ensuring their backend services are both functional and secure.",
            "label": "Express.js"
        },
        {
            "value": "In the MongoDB module, students are introduced to NoSQL database management. They learn to design flexible schemas, perform CRUD operations using MongoDB's native driver and Mongoose ORM, and optimize queries for better performance. This section ensures that students can efficiently manage data storage and retrieval in their backend applications.",
            "label": "MongoDB"
        },
        {
            "value": "The advanced backend section covers topics such as real-time communication using WebSockets, which is crucial for applications requiring live updates. Students also learn about user authentication and authorization with JWT, deploying Node.js applications with proper environment configuration, and implementing crash recovery strategies. This module prepares students to build, deploy, and maintain scalable and reliable backend systems.",
            "label": "Advanced Backend Features"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
}

export const dsaData =
{
    course: "DSA",
    description: "The Mind Coders DSA (Data Structures and Algorithms) course covers a wide array of topics essential for mastering algorithmic problem-solving.",
    content: [
        {
            "value": "This includes Asymptotic Analysis, Big O Notation, Omega Notation, Theta Notation, and the analysis of loops and recursion .",
            "label": "Analysis of Algorithms"
        },
        {
            "value": "Core topics such as Arrays, Linked Lists, Stacks, Queues, Trees, Hash Tables, Graphs, and more advanced structures like Tries, Segment Trees, Binary Indexed Trees, and Disjoint Sets ",
            "label": "Data Structures"
        },
        {
            "value": "This section focuses on essential mathematical computations like GCD, Prime Checking, Factorials, and Power Computations .",
            "label": "Mathematics for Algorithms"
        },
        {
            "value": "Topics include the 0-1 Knapsack problem, Matrix Chain Multiplication, Egg Dropping Puzzle, and the Longest Increasing Subsequence problem.",
            "label": "Advanced Algorithms"
        },
        {
            "value": "Techniques for pattern searching, including Naive and KMP algorithms, are discussed, along with Anagram Searching and Longest Substring with Distinct Characters .",
            "label": "String Processing"
        },
        {
            "value": "Beyond basic techniques, the course delves into advanced sorting algorithms like Radix Sort, Counting Sort, and efficient searching in sorted and rotated arrays .",
            "label": "Sorting and Searching"
        },
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
}

export const pythonFullStackData = {
    course: "Python FullStack",
    description: "The Mind Coders Python FullStack course covers essential topics for mastering full-stack development with Python and Django. The course emphasizes hands-on experience, including real-world projects.",
    content: [
        {
            "value": "This section introduces web development, covering client-server architecture, web development tools, and Linux commands.",
            "label": "Introduction to Web Development"
        },
        {
            "value": "This part covers the basics of Django, including installation, creating projects, understanding ASGI/WSGI, and working with Django apps.",
            "label": "Django Basics"
        },
        {
            "value": "Focuses on Django templates, including template language, inheritance, and adding CSS. Includes a mini project for practical understanding.",
            "label": "Django Templates"
        },
        {
            "value": "This section covers Django models, including database integration, model definitions, relationships, and CRUD operations.",
            "label": "Django Models"
        },
        {
            "value": "Covers building forms in Django, including HTML forms, model forms, validations, and advanced query techniques.",
            "label": "Building Forms in Django"
        },
        {
            "value": "Delves into advanced Django concepts like middleware, custom middleware creation, user authentication, signals, and annotations.",
            "label": "Advanced Django Concepts"
        },
        {
            "value": "Hands-on projects including an Expense Tracker and admin interface customization, emphasizing security considerations.",
            "label": "Projects"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
};

export const javaProgrammingData = {
    course: "Java Programming",
    description: "The Mind Coders Java Programming course provides a comprehensive introduction to Java, covering fundamental to advanced concepts essential for becoming proficient in Java development.",
    content: [
        {
            "value": "This section introduces Java basics, including variables, data types, operators, and the structure of a Java program.",
            "label": "Java Basics"
        },
        {
            "value": "Focuses on flow control mechanisms like if-else statements, switch statements, and various looping constructs in Java.",
            "label": "Flow Control"
        },
        {
            "value": "Covers the concept of functions, their applications, command-line arguments, and problem-solving using functions.",
            "label": "Functions"
        },
        {
            "value": "Introduces arrays, including single and multidimensional arrays, array sorting, and common array-based problems.",
            "label": "Arrays"
        },
        {
            "value": "This section explores strings in Java, including string manipulation, pattern searching, and string-related problem-solving.",
            "label": "Strings"
        },
        {
            "value": "Delves into Object-Oriented Programming (OOP) concepts such as classes, objects, inheritance, polymorphism, and abstraction.",
            "label": "Java OOPs"
        },
        {
            "value": "Covers advanced topics like file I/O, multithreading, exception handling, and working with BigInteger in Java.",
            "label": "Advanced Java"
        },
        {
            "value": "Introduces the Java Collections Framework, covering collections, generics, and the hierarchy of collection classes.",
            "label": "Java Collections"
        },
        {
            "value": "Focuses on Java Streams and Lambda Expressions, covering functional programming concepts and stream processing.",
            "label": "Java Streams & Lambda Expressions"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
};

export const javaFullStackData = {
    course: "Java FullStack",
    description: "The Mind Coders Java FullStack course covers key topics necessary for mastering full-stack development with Java. It provides a comprehensive learning experience with a focus on hands-on projects and real-world applications.",
    content: [
        {
            "value": "Covers Java OOP fundamentals, including concepts like classes, objects, inheritance, polymorphism, and encapsulation.",
            "label": "Java OOPS Fundamentals"
        },
        {
            "value": "Focuses on advanced Java features, including functional interfaces, lambda expressions, generics, and multithreading.",
            "label": "Advanced Java Features"
        },
        {
            "value": "Introduces Maven for project management, covering POM.xml, Maven repositories, and the Maven lifecycle.",
            "label": "Maven for Project Management"
        },
        {
            "value": "Covers the basics of Spring Boot, including embedded servers, logging levels, and Spring profiles.",
            "label": "Spring Boot Basics"
        },
        {
            "value": "Explores REST API development and Spring MVC, covering HTTP requests, responses, and API testing tools like POSTMAN.",
            "label": "REST API & Spring MVC"
        },
        {
            "value": "Delves into Spring IOC and dependency injection, covering annotations, enums, and bean configuration.",
            "label": "Spring IOC & Dependency Injection"
        },
        {
            "value": "Covers Java Database Connectivity (JDBC), including database connections, JPA, and Hibernate integration.",
            "label": "Java Database Connectivity (JDBC)"
        },
        {
            "value": "Includes a minor project focusing on a Digital Library, covering project planning, custom queries, and data modeling.",
            "label": "Minor Project: Digital Library"
        },
        {
            "value": "Focuses on unit testing with JUnit and Mockito, as well as caching concepts using Redis.",
            "label": "Unit Testing & Caching"
        },
        {
            "value": "Covers Spring Security, including basic authentication, authorization, and OAuth2 integration.",
            "label": "Spring Security & Authentication"
        },
        {
            "value": "Introduces Microservices architecture, covering Eureka Server, intercommunication, and Kafka integration.",
            "label": "Microservices"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
};

export const dataScienceCourseData = {
    course: "Data Science",
    description: "The Mind Coders Data Science course is a comprehensive program that takes you from a beginner to an advanced level data scientist, covering essential tools like Python, SQL, Power BI, and Tableau.",
    content: [
        {
            "value": "Covers basics of data analysis in Excel, including functions, pivot tables, and Visual Basic.",
            "label": "Excel for Data Analysis"
        },
        {
            "value": "Introduces SQL and DBMS concepts, including table creation, normalization, and SQL commands.",
            "label": "SQL and Database Management"
        },
        {
            "value": "Covers Python for data science, including data structures, data manipulation with NumPy and Pandas.",
            "label": "Python for Data Science"
        },
        {
            "value": "Explores data visualization techniques using Matplotlib, Seaborn, and Plotly for EDA.",
            "label": "Data Visualization"
        },
        {
            "value": "Introduces Power BI for data modeling and creating interactive dashboards with advanced features.",
            "label": "Power BI"
        },
        {
            "value": "Covers statistical concepts like probability distributions, hypothesis testing, and regression analysis.",
            "label": "Statistics for Data Science"
        },
        {
            "value": "Focuses on Tableau for creating advanced visualizations and designing dashboards.",
            "label": "Tableau for Data Visualization"
        },
        {
            "value": "Covers fundamentals of machine learning and AI, including regression, clustering, and classification models.",
            "label": "Machine Learning & AI"
        }
    ],
    projects: "Will upload soon",
    courseFeatures,
    faq
};