import React from "react";
import { baseUrl } from "../helpers/baseUrl";

const Benifits = () => {
  return (
    <>
      <section className="pt-8">
        <div className="text-center mb-8">
          <h3 className="text-2xl md:text-3xl font-bold mb-4">
            Benefits of Classroom Program
          </h3>
          <p className="text-base md:text-lg">
            Classroom programs are the most popular methods of learning opted by
            students who believe in perfection and who look forward to close,
            power-packed interactions to attain complete learning in one go.
            This is extremely successful and result oriented because of strong
            Peer group, personal attention, and round-the-clock availability of
            mentors.
          </p>
        </div>
        <div className="grid overflow-x-hidden grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div data-aos="fade-right" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/copywriting.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">Limited Batch Size</h4>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/presentation.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">Individual Attention</h4>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/discussion.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">Awesome Peer Group</h4>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/customer-review.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">
              Continuous Feedback &amp; Monitoring
            </h4>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/goals.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">100% Course Completion</h4>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/customer-support.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">Dedicated Doubt Support</h4>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/businessman.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">
              Dedicated Placement Support
            </h4>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="flex flex-col justify-center items-center bg-slate-50 p-4 border border-gray-200 rounded-lg text-center">
            <div className="w-12 h-12 mb-3">
              <img
                src={`${baseUrl()}/assets/img/animated-icons/interface.gif`}
                alt="icon"
                loading="lazy"
              />
            </div>
            <h4 className="text-lg font-semibold">
              In-class Live Projects &amp; Assignment Sessions
            </h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benifits;
