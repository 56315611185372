export const testimonialData = [
    {
        id: 1,
        content: "I am very happy to find this Institute because this is the best place to get master in Web Development Skill: Front-end, Back-end or Full Stack Web Development. The way of their teaching and increasing your Coding Skill is Very Special and Excellent. Guaranteed this is a top-notch institute which provides best classes and none of the other is best then this.",
        author: "Akshay Malviya",
        role: "React JS Developer",
        image: "assets/img/users.webp",
        rating: 5
    },
    {
        id: 2,
        content: "Exceptional coaching institute! The dedicated instructors and personalized approach make learning enjoyable and effective. Highly recommended! The way of their teaching and increasing your Coding Skill is Very Special and Excellent.",
        author: "Arjun Maheshwari",
        role: "Product Designer",
        image: "assets/img/users.webp",
        rating: 5
    },
    {
        id: 3,
        content: "Mind Coders is an exceptional company, not only for businesses but also for students. Their high-quality work, innovative approach, and outstanding customer service make them an ideal choice for educational courses. I highly recommend them for web development, software solutions, and digital marketing, especially for students looking to enhance their skills.",
        author: "Shiva Singh Rajawat",
        role: "FrontEnd Developer",
        image: "assets/img/users.webp",
        rating: 5
    }
];
