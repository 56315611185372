import React from "react";
import { frontend } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { frontendData } from "../../data/courseTabData";

const FrontEndPage = () => {
  return (
    <>
      <section className="ptb-120 d-flex align-items-center position-relative">
        <div className="container ">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">
                    Front-End Web Development Course
                  </h1>
                  <p className="course-overview p">
                    Basic overview of what you will learn during our 60 Days of
                    Front-End Web Development Training in Indore. Enroll Now!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
              <div className="hero-content-wrap">
                <img
                  className="course-image"
                  src={`${window.location.origin.toString()}/assets/img/courses-logo/frontend-logo.webp`}
                  alt="img"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {frontend.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>{topic}</li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>

      <Tabs content={frontendData} />
    </>
  );
};

export default FrontEndPage;
