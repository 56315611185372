import React, { useState } from "react";
import { Link } from "react-router-dom";
import Path from "../routes/Path";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <header className="py-[40px]">
        <nav className="fixed top-0 w-screen flex items-center justify-between p-3 shadow-lg bg-white bg-opacity-50 backdrop-blur-md z-50">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img
                src={`${window.location.origin.toString()}/assets/img/mindcoderlogo.webp`}
                alt="logo"
                className="h-12"
                loading="lazy"
              />
            </Link>
          </div>

          <div className="lg:hidden flex items-center">
            <button
              className="text-gray-600 hover:text-gray-900"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <i className="fa fa-bars"></i>
            </button>
          </div>

          <div className="hidden lg:flex flex-grow justify-end">
            <ul className="flex space-x-4 text-gray-700 font-bold">
              <li>
                <Link to="/" className="hover:text-blue-600">
                  Home
                </Link>
              </li>

              <li className="relative group">
                <Link
                  to="/courses"
                  className="flex items-center hover:text-blue-600"
                >
                  Courses <i className="fa fa-chevron-down ml-1"></i>
                </Link>
                <div className="absolute -left-72 z-50 hidden w-[800px] rounded-xl bg-white shadow-lg group-hover:block">
                  <div className="p-4">
                    <h6 className="font-bold mb-2">Courses</h6>
                    <div className="grid grid-cols-3">
                    <Link
                      to={Path.COURSE_DSA}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                      <i class="fa-regular fa-file-code"></i>
                      </span>{" "}
                      DSA
                    </Link>
                    <Link
                      to={Path.COURSE_PYTHON}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                      <i class="fa-brands fa-python"></i>
                      </span>{" "}
                      Python Programming
                    </Link>
                    <Link
                      to={Path.COURSE_PYTHON_FULLSTACK}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                      <i class="fa-brands fa-python"></i>
                      </span>{" "}
                      Python Fullstack
                    </Link>
                    <Link
                      to={Path.COURSE_JAVA}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                      <i class="fa-brands fa-java"></i>
                      </span>{" "}
                      Java Programming
                    </Link>
                    <Link
                      to={Path.COURSE_JAVA_FULLSTACK}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                      <i class="fa-brands fa-java"></i>
                      </span>{" "}
                      Java Fullstack
                    </Link>
                    <Link
                      to={Path.COURSE_FRONTEND}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                        <i className="fa-solid fa-code"></i>
                      </span>{" "}
                      Frontend
                    </Link>
                    <Link
                      to={Path.COURSE_REACT}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                        <i className="fa-brands fa-react"></i>
                      </span>{" "}
                      React JS
                    </Link>
                    <Link
                      to={Path.COURSE_BACKEND}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                        <i className="fa-solid fa-server"></i>
                      </span>{" "}
                      Backend
                    </Link>
                    <Link
                      to={Path.COURSE_MERN}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                        <i className="fa-brands fa-node-js"></i>
                      </span>{" "}
                      MERN Stack
                    </Link>
                    <Link
                      to={Path.COURSE_DATA_ANALITICS}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                        <i className="fa-solid fa-chart-line"></i>
                      </span>{" "}
                      Data Analytics
                    </Link>
                    <Link
                      to={Path.COURSE_DATA_SCIENCE}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                      <i class="fa-solid fa-chart-pie"></i>
                      </span>{" "}
                      Data Science
                    </Link>
                    <Link
                      to={Path.COURSE_DIGITALMARKETING}
                      className="block py-2 px-4 hover:bg-gray-100"
                    >
                      <span className="me-2">
                        <i className="fa-solid fa-rectangle-ad"></i>
                      </span>{" "}
                      Digital Marketing
                    </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <Link to="/placement" className="hover:text-blue-600 font-bold">
                  Placements
                </Link>
              </li>
              <li>
                <Link to="/jobs" className="hover:text-blue-600">
                  Jobs
                </Link>
              </li>
              <li>
                <Link to="/internship" className="hover:text-blue-600">
                  Internship
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-blue-600">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-blue-600">
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="https://lms.mindcoders.in/login"
                  target="_blank"
                  className="bg-[#05314b] text-white px-4 py-2 rounded-tr-2xl rounded-bl-2xl hover:rounded-tr-none hover:rounded-bl-none hover:rounded-br-2xl hover:rounded-tl-2xl"
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        {/* Offcanvas Menu Start */}
        <div
          className={`fixed inset-0 z-50 bg-black bg-opacity-50 lg:hidden transition-opacity duration-300 ${
            isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
          id="offcanvasWithBackdrop"
        >
          <div
            className={`fixed inset-y-0 w-[100%]  max-w-xs bg-white shadow-lg transition-transform duration-300 ${
              isMenuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="p-4 flex items-center justify-between">
              <Link to="/" className="flex items-center">
                <img
                  src="assets/img/mindcoderlogo.webp"
                  alt="logo"
                  className="h-[70px]"
                  loading="lazy"
                />
              </Link>
              <button
                type="button"
                className="text-gray-600"
                onClick={() => setIsMenuOpen(false)}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="px-4 text-xl">
              <ul className="">
                <li>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Home
                  </Link>
                </li>

                <li className="relative group">
                  <Link
                    to="/courses"
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Courses
                  </Link>
                </li>
                <li>
                  <Link
                    to="/placement"
                    className="block py-2 text-gray-700 hover:text-blue-600 font-bold"
                  >
                    Placements
                  </Link>
                </li>

                <li>
                  <Link
                    to="/jobs"
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Jobs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/internship"
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    Internship
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="block py-2 text-gray-700 hover:text-blue-600"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://lms.mindcoders.in/login"
                    target="_blank"
                    className="block py-2 px-4 bg-blue-600 text-white rounded"
                  >
                    Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Offcanvas Menu End */}
      </header>
    </>
  );
};

export default NavBar;
